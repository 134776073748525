import { ArContainer } from 'src/components/ArContainer'
import { ArDescriptionList } from 'src/components/ArDescriptionList'
import { ArSeo } from 'src/components/ArSeo'
import { ArTextHero } from 'src/components/ArTextHero'
import { ROUTES } from 'src/config/routes'
import React from 'react'
import { EULA_DESCRIPTION_LIST_ITEMS } from 'src/data/eulaDescriptionList'

const EulaPage = () => {
  return (
    <>
      <ArSeo route={ROUTES.eula()} />
      <ArContainer>
        <ArTextHero title="End User License Agreement (EULA)" />
        <ArDescriptionList data={EULA_DESCRIPTION_LIST_ITEMS} />
      </ArContainer>
    </>
  )
}

export default EulaPage
