import { TRichText } from 'src/utils/parseRichText'

export const EULA_DESCRIPTION_LIST_ITEMS = [
  {
    title: 'Important notes',
    description: [
      {
        type: 'p',
        value:
          "This EULA covers use and access of the software named ArtificialOS, a software-as-a-service web application, deployed and managed by Artificial Labs in an isolated cloud environment for the 'end user', providing a platform for the ingestion, submission, and processing of insurance risks across the lifecycle, referred to in this document as the ArtificialOS Suite (including Contract Builder, Brossa, and other products). This EULA also covers the content of the ArtificialOS Suite (including Contract Builder, Brossa, and other products) and any associated literature.",
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          'Please ensure that you read the terms of this EULA carefully before purchasing, using and accessing the ArtificialOS Suite (including Contract Builder, Brossa and other products). Your use, and access of, the ArtificialOS Suite (including Contract Builder, Brossa and other products) is subject to the terms set out in this EULA.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          'There are important terms in this EULA such as terms which limit and exclude our liability to you and obligations you have when using the ArtificialOS Suite (including Contract Builder, Brossa and other products). It is therefore important that you read them carefully. ',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          'Any use, reproduction, or redistribution of the ArtificialOS Suite (including Contract Builder, Brossa and other products) that is not in accordance with the terms of this EULA is expressly prohibited.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          'Personal data that we collect in the course of your use, and access, of the ArtificialOS Suite (including Contract Builder, Brossa and other products) shall be treated in accordance with our privacy policy which is available at https://artificial.io/privacy/.',
      },
    ] as TRichText,
  },
  {
    title: 'Introduction',
    description: [
      {
        type: 'p',
        value:
          '1.1 We are ARTIFICIAL LABS LTD registered in England and Wales under company number 08766928 with our office at 10 Bow Lane, London, EC4M 9AL (we, us, our).',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '1.2 This End User Licence Agreement (EULA) constitutes a legal agreement between us and you (or your) for your use and access of the ArtificialOS Suite (including Contract Builder, Brossa and other products) in the course of your business.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '1.3 By using the ArtificialOS Suite, you agree to be bound by the terms of this EULA.',
      },
    ] as TRichText,
  },
  {
    title: 'This License',
    description: [
      {
        type: 'p',
        value:
          '2.1 Where you use the ArtificialOS Suite (including Contract Builder, Brossa and other products) in accordance with this EULA:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '2.1.1 we grant to you a non-exclusive, non-transferable, revocable licence to use the ArtificialOS Suite (including Contract Builder, Brossa and other products) for use within your business and to access the content provided for internal business purposes only; and',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '2.1.2 we will provide the ArtificialOS Suite (including Contract Builder, Brossa and other products) to meet the description, in all material respects, of the ArtificialOS Suite (including Contract Builder, Brossa and other products) as set out in the documentation provided to you by us. We may update or require you to update the Software, provided that the ArtificialOS Suite (including Contract Builder, Brossa and other products) shall meet the description, in all material respects, that we provided to you before you bought it.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '2.2 As long as you do not violate any restrictions set out in this EULA, you may make a limited number of copies of the ArtificialOS Suite (including Contract Builder, Brossa and other products), strictly as is required for the purpose of providing a back-up in accordance with your own internal operating procedures.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '2.3 Each user must create a user account with us in order to have access to the ArtificialOS Suite (including Contract Builder, Brossa and other products).',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '2.4 This EULA does not entitle you to other services such as implementation support or training in how to use our ArtificialOS Suite (including Contract Builder, Brossa and other products).',
      },
    ] as TRichText,
  },
  {
    title: 'Your Responsibilities',
    description: [
      {
        type: 'p',
        value: '3.1 You are responsible for keeping any user account details you create safe.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.2 You agree to comply with all applicable laws relevant to use of the ArtificialOS Suite (including Contract Builder, Brossa and other products), including all applicable technology control or export laws and regulations.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.3 Where we permit multiple users on one single sign on, or access for multiple users from a single source of download, you are responsible for:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.3.1 The acts and omissions of your directors, employees, contractors, sub-contractors or agents (Personnel) with respect to their use of the ArtificialOS Suite (including Contract Builder, Brossa and other products) and you remain liable for the acts and omissions of your Personnel in respect of their use of the ArtificialOS Suite (including Contract Builder, Brossa and other products); and',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '3.3.2 ensuring that your Personnel are provided with a copy of this EULA and comply with its terms.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.4 You are responsible for making sure all your payments for the ArtificialOS Suite (including Contract Builder, Brossa and other products) are up to date and in accordance with our normal payment terms which are set out in the documentation provided by us to you.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '3.5 You must notify us immediately in writing if:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.5.1 you know of or suspect there has been any unauthorised access of or use of the ArtificialOS Suite (including Contract Builder, Brossa and other products); and/or',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.5.2 you are aware of or suspect that your own network and systems have been compromised (including any attack on your systems such as a denial of service attack or ransomware).',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.6 Where we have been notified of any of the events above, we shall be entitled to suspend access to the ArtificialOS Suite (including Contract Builder, Brossa and other products) to you, without any liability to you, until we are satisfied that we are able to provide the ArtificialOS Suite (including Contract Builder, Brossa and other products) to you without the risk that:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.6.1 the ArtificialOS Suite (including Contract Builder, Brossa and other products) may be accessed by an unauthorised person; or',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '3.6.2 that our own network and systems, or that of a third-party, could be compromised.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.7 Where you access our ArtificialOS Suite (including Contract Builder, Brossa and other products) through your browser, it is your responsibility to ensure that your browser is up to date.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '3.8 You will require an internet connection which you must procure at your own expense, to use and access the ArtificialOS Suite (including Contract Builder, Brossa and other products). We will not be responsible for any interruption to your use of the ArtificialOS Suite (including Contract Builder, Brossa and other products) due to interruptions to your internet connection.',
      },
    ] as TRichText,
  },
  {
    title: 'Restrictions',
    description: [
      {
        type: 'p',
        value: '4.1 You must not:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.1.1 sell, distribute, reproduce, transfer, publicly display, translate, modify, adapt, create derivative works from, deconstruct, reverse engineer, decompile or disassemble, rent, lease, loan, sub-license or otherwise deal in copies or reproductions of the ArtificialOS Suite (including Contract Builder, Brossa and other products) to other parties in any way except as expressly permitted by this EULA;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.1.2 remove, delete, obscure, disable, modify, add to, tamper with, or circumvent any program code or data, copyright, trademark, or other proprietary notices, labels or copy protection ArtificialOS Suite (including Contract Builder, Brossa and other products) contained on or within the ArtificialOS Suite (including Contract Builder, Brossa and other products);',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.1.3 use the ArtificialOS Suite (including Contract Builder, Brossa and other products) for any illegal or immoral purposes;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.1.4 exploit the Software (or part of), content contained in it or any of our related literature, for any commercial purpose;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.1.5 export or re-export the ArtificialOS Suite (including Contract Builder, Brossa and other products) or any copy or adaptation in violation of any applicable laws or regulations;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.1.6 create data or executable programs which mimic data or functionality in the ArtificialOS Suite (including Contract Builder, Brossa and other products);',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.1.7 use the ArtificialOS Suite (including Contract Builder, Brossa and other products) to make any other ArtificialOS Suite (including Contract Builder, Brossa and other products) or programme which is substantially similar to the ArtificialOS Suite (including Contract Builder, Brossa and other products).',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '4.2 You agree not to perform any security testing on the ArtificialOS Suite (including Contract Builder, Brossa and other products) unless agreed with us in writing.',
      },
    ] as TRichText,
  },
  {
    title: 'Ownership',
    description: [
      {
        type: 'p',
        value:
          '5.1 You agree that the ArtificialOS Suite (including Contract Builder, Brossa and other products) and any intellectual property rights contained in the ArtificialOS Suite (including Contract Builder, Brossa and other products) (including any content that was provided with the ArtificialOS Suite (including Contract Builder, Brossa and other products) and any accompanying literature) anywhere in the world belong to us or anyone we hold a licence with.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '5.2 Except as expressly set out in this EULA, you gain no rights in or in relation to the ArtificialOS Suite (including Contract Builder, Brossa and other products).',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '5.3 You do not have permission and are not permitted to access the ArtificialOS Suite (including Contract Builder, Brossa and other products) in source code form.',
      },
    ] as TRichText,
  },
  {
    title: 'If the Software is Faulty',
    description: [
      {
        type: 'p',
        value:
          '6.1 In the event that you believe the Artificial Software is faulty in any way, please contact Artificial using the appropriate channels. Artificial will respond in a timely manner and will help to find a resolution.',
      },
    ] as TRichText,
  },
  {
    title: 'Limit of Our Liability',
    description: [
      {
        type: 'p',
        value:
          '7.1 You agree that the ArtificialOS Suite (including Contract Builder, Brossa and other products) has not been developed to meet your particular requirements, including any legal or regulatory requirements you may have. You must ensure that the ArtificialOS Suite (including Contract Builder, Brossa and other products) meets your requirements.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '7.2 We provide the ArtificialOS Suite (including Contract Builder, Brossa and other products) on an “as is” basis only, and do not warrant that:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '7.2.1 your use of the ArtificialOS Suite (including Contract Builder, Brossa and other products) will be uninterrupted or error-free; or',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '7.2.2 the ArtificialOS Suite (including Contract Builder, Brossa and other products) will be free from vulnerabilities or viruses.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '7.3 We do not exclude or limit any liability for (i) death or personal injury arising from our negligence; (ii) fraud or fraudulent misrepresentation; or (iii) any other liability to the extent it cannot be excluded or limited by law.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '7.4 We will not be liable to you, under or in connection with this EULA or the provision of the ArtificialOS Suite (including Contract Builder, Brossa and other products), for:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '7.4.1 business interruption;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '7.4.2 loss of anticipated savings;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '7.4.3 loss of business opportunity, goodwill or reputation;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '7.4.4 loss or corruption of data or information;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '7.4.5 loss of revenue or profit;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '7.4.6 any indirect or consequential loss or damage; and/or',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '7.4.7 any other unforeseeable losses arising out of unusual or special circumstances.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '7.5 Other than the losses set out in clause 7.4 (for which we are not liable), our maximum total liability under or in connection with this EULA will, where it is permitted by law, be limited to £1,000,000.',
      },
    ] as TRichText,
  },
  {
    title: 'Termination and Suspension',
    description: [
      {
        type: 'p',
        value:
          '8.1 If you commit a serious or persistent breach of this EULA, we may terminate this EULA immediately on written notice to you, or suspend your access to the Software. If the breach is capable of being addressed and put right by you, you will have 14 days to address the breach and if you fail to adequately address the breach in this time, then this EULA will be terminated or we may suspend your access to the Software.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '8.2 We may also terminate this EULA immediately on written notice to you in the event that you (or your business) become insolvent, fail to pay your debts, become the subject of any voluntary or involuntary proceeding in bankruptcy, liquidation, dissolution, receivership, attachment or composition for the benefit of creditors or in the event that you (or your business) undergo a change of control (within the meaning of section 1124 of the Corporation Tax Act 2010).]',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '8.3 Where this EULA has been terminated for any reason:',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '8.3.1 any rights for you to use the Software, the content contained within it and any associated literature will end immediately;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '8.3.2 you must stop doing anything that was only permitted under this EULA; and',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '8.3.3 you must immediately and permanently remove the Software from all computers, mobile devices or any other computer equipment in your control. On our request, you must confirm in writing that you have done so.',
      },
    ] as TRichText,
  },
  {
    title: 'General',
    description: [
      {
        type: 'p',
        value: '9.1 Interpretation of this EULA: In this EULA:',
      },
      {
        type: '9.1.1 a person means an individual, a firm or a company (whether or not having a separate legal identity from its members or owners);',
      },
      {
        type: 'p',
        value: '9.1.2 clause, schedule and paragraph headings shall not affect the interpretation of this EULA;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.1.3 references to statutes, regulations or other legislation or enactments referenced herein shall be deemed to be references to that enactment as amended, supplemented, re-enacted or replaced from time to time;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.1.4 the words include, including and similar words or expressions will not limit the meaning of the words that come before them;',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.1.5 reference to writing or written includes email but not any other form of electronic communication; and',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value: '9.1.6 each of the parties shall be referred to as a party or together, the parties.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.2 Third party rights: For the purposes of the Contracts (Rights of Third Parties) Act 1999, this EULA is not intended to and does not give any person who is not a party to it any right to enforce any of its provisions. However, this does not affect any rights or remedy of such a person that exists or is available apart from that Act.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.3 Costs: Each party is responsible for its legal and other costs in relation to the preparation and performance of this EULA.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.4 Survival of terms: The parties intend the following terms to survive termination: clauses 4, 5, 7, 8, and 9 shall survive termination of this EULA.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.5 Relationship of the parties: The parties are independent businesses and not partners, principal and agent, or employer and employee, or in any other relationship of trust to each other.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.6 Assignment and other dealings: No party may assign, subcontract or encumber any right or obligation under this EULA, in whole or in part, without the other party’s prior written consent or except as expressly permitted in this EULA.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.7 Entire agreement: This EULA, and any document referred to in it, contains the whole EULA between the parties relating to its subject matter and supersedes any prior EULAs, representations or understandings between them unless expressly referred to in this EULA. Each party acknowledges that it has not relied on, and will have no remedy in respect of, any representation (whether innocent or negligent) made but not covered in this EULA. Nothing in this clause limits or excludes any liability for fraud or fraudulent misrepresentation.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.8 Variation: We may amend this EULA at any time by giving you written notice. Your continued use of the ArtificialOS Suite (including Contract Builder, Brossa and other products) and any associated content or literature following receipt of the notice will be taken as your acceptance of the new terms of this EULA. If you do not accept this EULA or any amendments to it, you must immediately stop using the ArtificialOS Suite (including Contract Builder, Brossa and other products) and follow the procedure set out at clause 8.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.9 Severability: If any clause in this EULA (or part of a clause) is or becomes illegal, invalid or unenforceable under applicable law, but would be legal, valid and enforceable if the clause or some part of it was deleted or modified (or the duration of the relevant clause reduced), the relevant clause (or part of it) will apply with such deletion or modification as may be required to make it legal, valid and enforceable, and the parties will promptly and in good faith seek to negotiate a replacement provision consistent with the original intent of this EULA as soon as possible.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.10 Waiver: No delay, act or omission by either party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.11 Notices: Notices under this EULA must be in writing and sent to the other party’s address or email address. For the purpose of this clause, we will use the details where you provided to us when you downloaded the ArtificialOS Suite (including Contract Builder, Brossa and other products). Letters sent in the United Kingdom will be deemed delivered 3 business days (excluding English Bank Holidays), after sending. Emails will be deemed delivered the same day (or the next business day, if sent on a non-business day or after 5pm on any business day at the recipient’s location).',
      },
      {
        type: 'br',
      },
      {
        type: 'p',
        value:
          '9.12 Governing law and jurisdiction: This EULA is governed by the law of England and Wales. All disputes under this EULA will be subject to the exclusive jurisdiction of the courts of England and Wales.',
      },
    ] as TRichText,
  },
]
